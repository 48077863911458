<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <div class="wrapper">
        <button class="btn-green qrButton btn" @click="toCodes">
          <h5>
            Eigene QR-Codes verwalten
          </h5>
        </button>
        <h2 class="mt-3">Daten bearbeiten</h2>

        <label class="editLabel">
          Vorname*:
          <input type="text" required v-model="user.firstname">
        </label>
        <label class="editLabel">
          Nachname*:
          <input type="text" required v-model="user.lastname">
        </label>
        <label class="editLabel">
          Telefonnummer*:
          <input type="tel" required v-model="user.tel">
        </label>
        <label class="dateLabel">
          Geburtsdatum:
          <input type="date" disabled v-model="user.dateOfBirth">
        </label>

        <span style="color: red; margin-top: 16px">
        *Pflichtfeld
        </span>
        <br>
        <button class="btn-green saveButton btn" @click="save">
          <h3>
            Speichern
          </h3>
        </button>
        <br>
        <button class="btn-red logoutButton btn" @click="logout">
          <h4>
            Abmelden
          </h4>
        </button>
      </div>

      <b-modal id="successModal" title="Checkin" hide-footer>
        <p class="my-4">Ihre Daten wurden aktualisiert!</p>
        <b-button class="mt-3" variant="success" block @click="$bvModal.hide('successModal')">OK</b-button>
      </b-modal>

    </div>

    <menu-bar active-button="settings"></menu-bar>
  </div>
</template>

<script>
import MenuBar from "../components/MenuBar";
import LogoBanner from "../components/LogoBanner";
import {mapGetters} from "vuex";

export default {
  name: "Settings",
  components: {
    LogoBanner,
    MenuBar,
  },
  methods: {
    toCodes() {
      this.$router.push('QR')
    },
    save() {
      this.$bvModal.show("successModal")
    },
    logout() {
      this.$store.state.user = {}
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
    user() {
      return this.getUser
    }
  }
}
</script>

<style scoped>

h2 {
  text-align: center;
  font-weight: bold;
}

h5 {
  margin: 0;
}

.editLabel {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.editLabel input, .dateLabel input {
  background: #E5E5E5;
  border: 0;
  border-radius: 5px;
  outline: 0;
  height: 32px;
  padding: 4px;
}

.dateLabel {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dateLabel input {
  color: black
}

.saveButton {
  margin-top: 12px;
}

.logoutButton {
  margin-top: 12px;
  height: 40px;
}

.qrButton {
  margin-top: 16px;
}
</style>
